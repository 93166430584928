import { Signal, useSignal } from "@preact/signals";
import { useEffect, useRef } from "preact/hooks";
import { BaseGame } from "~/games/deps.ts";
import { Button } from "~/components/Button.tsx";

export const Lobby = (
    { gameName, onClose }: { gameName: string; onClose: () => void },
) => {
    const games = useSignal([]);
    const name = useSignal("");

    useEffect(() => {
        fetch(`/api/games/${gameName}`).then((res) => res.json()).then(
            (data) => {
                console.log(data);
                games.value = data.result;
            },
        );
    }, []);

    return (
        <div class="ui flex flex-col items-center p-4 min-h-screen text-zinc-200 bg-slate-900">
            <div class="max-w-screen-2xl mx-auto w-full">
                <div class="flex justify-between items-center gap-8">
                    <Button
                        class="text-2xl"
                        color="danger"
                        onClick={onClose}
                        title="back"
                        autoFocus
                    >
                        <span aria-hidden="true">←</span>
                    </Button>
                    <h1 class="text-3xl font-bold capitalize text-center">
                        {gameName} Games
                    </h1>
                    <div></div>
                </div>
                <form class="flex gap-4 items-end my-4 text-xl justify-center">
                    <div class="flex flex-col">
                        <label for="game-name">Game</label>
                        <input
                            id="game-name"
                            type="search"
                            class="text-zinc-800 px-2 py-1 rounded"
                            value={name.value}
                            onInput={(
                                e,
                            ) => (name.value = e.currentTarget.value)}
                        />
                    </div>
                    <div class="flex gap-2">
                        <Button>Search</Button>
                    </div>
                </form>

                {games.value.length > 0
                    ? (
                        <table class="w-full border">
                            <thead>
                                <tr>
                                    <th>Game</th>
                                    <th>Players</th>
                                    <th>Mode</th>
                                </tr>
                            </thead>
                            <tbody>
                                {games.value.map((game) => (
                                    <tr>
                                        <td>{game.name}</td>
                                        <td>{game.players}</td>
                                        <td>{game.status}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )
                    : <p class="text-center">No games found</p>}
            </div>
        </div>
    );
};

export const HostDialog = ({ onClose }: { onClose: () => void }) => {
    const ref = useRef<HTMLDialogElement>(null);
    useEffect(() => {
        if (ref.current) {
            ref.current.showModal();
        }
    }, [ref.current]);

    return (
        <dialog class="ui" ref={ref}>
            <h1>Host Game</h1>
            <form>
                <label for="game-name">Game</label>
                <input id="game-name" type="text" />
                <label for="players">Players</label>
                <input id="players" type="number" />
                <label for="mode">Mode</label>
                <input id="mode" type="text" />
                <button>Host</button>
            </form>
        </dialog>
    );
};

export default function LobbyBrowser({ game }: { game: Signal<BaseGame> }) {
    const gameName = "boxes";
    const focus = useSignal<"lobby" | "host" | null>(null);

    if (focus.value === null) {
        return (
            <div class="flex flex-col gap-4 items-center">
                <h1 class="capitalize">{gameName}</h1>
                <div class="ui flex gap-4 justify-center">
                    <Button
                        autoFocus
                        onClick={() => focus.value = "lobby"}
                    >
                        Join
                    </Button>
                    <Button>Host</Button>
                </div>
            </div>
        );
    }

    if (focus.value === "host") {
        return <HostDialog onClose={() => focus.value = null} />;
    }

    return <Lobby gameName={gameName} onClose={() => focus.value = null} />;
}
