// You can write more code here
import { Signal } from "@preact/signals";
import { Scene } from "~/games/deps.ts";
import { ui } from "~/games/signals.ts";
import Game from "../Game.ts";
import { Button } from "~/components/Button.tsx";
import { ImageList } from "~/games/sprite-packer/components/ImageList.tsx";

/* START OF COMPILED CODE */

export default class GameScene extends Scene {
  constructor() {
    super("game");

    /* START-USER-CTR-CODE */
    // Write your code here.
    /* END-USER-CTR-CODE */
  }

  editorCreate(): void {
    this.events.emit("scene-awake");
  }

  /* START-USER-CODE */

  // Write your code here

  create() {
    this.editorCreate();

    // get all images that were preloaded
    const uuidPattern =
      /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

    const images = Object.entries(this.textures.list).map(([key, value]) => ({
      key,
      elm: value.source[0].image,
      src: value.source[0].image.src,
    })).filter((image) =>
      !image.key.startsWith("_") && image.key.search(uuidPattern) === -1
    );

    ui.value = [
      ({ game }: { game: Signal<Game> }) => [
        Button({
          class: "absolute top-0 right-0",
          children: "Quit",
          onClick: () => game.value.quit(),
        }),
        ImageList({ images }),
      ],
    ];
  }

  /* END-USER-CODE */
}

/* END OF COMPILED CODE */

// You can write more code here
