import { useEffect, useId } from "preact/hooks";
import { useSignal } from "@preact/signals";
import { Game, GameInstance, gameList, GameProps, games } from "~/games/mod.ts";
import GameSelector from "../components/GameSelector.tsx";
import { selectedGame, ui } from "~/games/signals.ts";
import { isPaused } from "~/games/eikoo/signals.ts";
import { BaseGame } from "~/games/deps.ts";

type IslandProps = {
  class?: string;
  game?: Game;
  id?: string;
  baseURL?: string;
};
type Props = GameProps & IslandProps;

export default function GameIsland(props?: Props) {
  const { game, id, class: htmlClass, ...gameProps } = { ...props };
  const gameId = id ?? useId();
  const gameObj = useSignal<GameInstance | undefined>(undefined);

  if (game) {
    selectedGame.value = game;
  }

  useEffect(() => {
    if (!selectedGame.value) return;
    const GameInfo = selectedGame.value in games
      ? games[selectedGame.value]
      : undefined;
    if (!GameInfo) {
      selectedGame.value = undefined;
      return;
    }

    const GameClass = GameInfo.class;

    gameObj.value = new GameClass({
      id: gameId,
      ...gameProps,
      baseURL: props?.baseURL,
    });

    isPaused.value = gameObj.value.isPaused;

    gameObj.value.events.on("pause", () => {
      isPaused.value = true;
      gameObj.value?.sound.pauseAll();
    });

    gameObj.value.events.on("resume", () => {
      isPaused.value = false;
      gameObj.value?.sound.resumeAll();
    });

    const keydown = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        console.log("escape", gameObj.value?.isPaused);
        if (isPaused.value) {
          gameObj.value?.socket.send({ type: "resume" });
        } else {
          gameObj.value?.socket.send({ type: "pause" });
        }
      }
    };

    globalThis.addEventListener("keydown", keydown);

    const url = new URL(window.location.href);
    try {
      console.log("connecting", gameObj.value);
      if (gameObj.value.hasApi) {
        const socket = new WebSocket(
          `ws${
            url.protocol === "https:" ? "s" : ""
          }://${url.host}/api/games/${game}/${
            url.pathname.split("/")[3] ?? "public"
          }/ws`,
        );
        socket.onmessage = (e) => {
          const data = JSON.parse(e.data);
          if (data.type === "pause") {
            gameObj.value?.pause();
          } else if (data.type === "resume") {
            gameObj.value?.resume();
          }

          console.log("message", data);
        };

        (gameObj.value as BaseGame).connection = socket;
      }
    } catch (e) {
      console.error("error", e);
    }
    return () => {
      gameObj.value?.quit();
      globalThis.removeEventListener("keydown", keydown);
    };
  }, [selectedGame.value]);
  return (
    <div class="font-mono">
      <div
        class={`relative h-screen w-screen overflow-hidden ${htmlClass}`}
        id={gameId}
      >
        {!gameObj.value
          ? (
            <>
              {game
                ? (
                  <div class="h-[100%] text-center">
                    Loading {game}...
                  </div>
                )
                : <GameSelector games={games} />}
            </>
          )
          : (
            <div class="fixed text-4xl select-none pointer-events-none inset-0">
              {Array.isArray(ui.value) &&
                ui.value?.map((UIComponent) => (
                  <UIComponent
                    key={UIComponent.id}
                    game={gameObj}
                  />
                ))}
            </div>
          )}
      </div>
    </div>
  );
}
